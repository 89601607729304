<template>
  <vx-card>
    <div class="mb-6 text-5xl text-black">Sales Achievement</div>
    <div class="vx-col w-full mb-base space-y-8">
      <!-- Filters -->
      <div class="space-y-4">
        <div class="w-full flex flex-row items-center gap-4">
          <div class="vx-col">
            <span>Region</span>
            <multiselect
              class="selectExample"
              v-model="selectedRegions"
              :options="optionRegions"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Operating Unit</span>
            <multiselect
              class="selectExample"
              v-model="selectedOUs"
              :options="optionOUs"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Territory</span>
            <multiselect
              class="selectExample"
              v-model="selectedTerritories"
              :options="optionTerritories"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
        </div>
        <div class="w-full flex flex-row items-center gap-4">
          <div class="vx-col">
            <span>Supervisor</span>
            <multiselect
              class="selectExample"
              v-model="selectedSupervisors"
              :options="optionSupervisors"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Salesman Type</span>
            <multiselect
              class="selectExample"
              v-model="selectedSalesmanTypes"
              :options="optionSalesmanTypes"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
          <div class="vx-col">
            <span>Salesman</span>
            <multiselect
              class="selectExample"
              v-model="selectedSalesman"
              :options="optionSalesman"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :custom-label="customLabel"
              track-by="id"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- Info Chart -->
    <vx-card>
      <div class="flex flex-row justify-around">
        <div class="flex flex-col">
          <StatisticInfo :total="salesman.toString()" title="Salesman" />
          <StatisticInfo
            :total="registered_outlet.toString()"
            title="Registered Outlet"
          />
          <StatisticInfo
            :total="active_outlet.toString()"
            title="Active Outlet"
          />
          <StatisticInfo
            :total="sales_productivity + '%'"
            title="Sales Productivity"
          />
        </div>
        <div>
          <div class="flex flex-row gap-20">
            <DoughnutChart
              title="Visit Plan"
              :value="this.visit_plan.done"
              :total="this.visit_plan.target"
            />
            <DoughnutChart
              title="Effective Call"
              :value="this.effective_call.done"
              :total="this.effective_call.target"
            />
            <DoughnutChart
              title="Sales Achievement"
              :value="this.sales_achievement.done"
              :total="this.sales_achievement.target"
            />
          </div>
          <div class="flex flex-row gap-20">
            <DoughnutChart
              title="Principal Transaction"
              :value="this.principal_transaction.done"
              :total="this.principal_transaction.target"
            />
            <DoughnutChart
              title="SKU Transaction"
              :value="this.sku_transaction.done"
              :total="this.sku_transaction.target"
            />
            <DoughnutChart
              title="Time Gone"
              :value="this.time_gone.done"
              :total="this.time_gone.target"
            />
          </div>
          <div class="grid grid-cols-2 mt-8">
            <StatisticInfo
              :total="formatPrice(this.ar_overdue.total_amount)"
              title="A/R Overdue"
              :caption="`of ${this.ar_overdue.document_count} documents`"
              :uppercase="false"
            />
            <StatisticInfo
              :total="formatPrice(this.ar_outstanding.total_amount)"
              title="A/R Outstanding"
              :caption="`of ${this.ar_outstanding.document_count} documents`"
              :uppercase="false"
            />
          </div>
        </div>
      </div>
    </vx-card>
    <!-- Bar Chart -->
    <div class="mt-8 w-1/2">
      <div class="my-6 text-3xl font-bold">Daily Regular Sales</div>
      <BarChart :chartData="this.dailyRegularSalesData" />
    </div>
  </vx-card>
</template>
<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import StatisticInfo from '@/components/statistic-info/StatisticInfo.vue';

export default {
  components: {
    DoughnutChart,
    BarChart,
    StatisticInfo,
  },
  data() {
    return {
      selectedTerritories: [],
      optionTerritories: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      selectedRegions: [],
      optionRegions: [],
      regionIDs: [],
      regionCodes: [],
      regionNames: [],
      selectedOUs: [],
      optionOUs: [],
      ouIDs: [],
      ouCodes: [],
      ouNames: [],
      selectedSupervisors: [],
      optionSupervisors: [],
      supervisorIDs: [],
      supervisorCodes: [],
      supervisorNames: [],
      selectedSalesmanTypes: [],
      optionSalesmanTypes: [],
      salesmanTypeIDs: [],
      salesmanTypeCodes: [],
      salesmanTypeNames: [],
      selectedSalesman: [],
      optionSalesman: [],
      salesmanIDs: [],
      salesmanCodes: [],
      salesmanNames: [],
      salesman: 0,
      registered_outlet: 0,
      active_outlet: 0,
      sales_productivity: 0,
      visit_plan: { done: 1, target: 1 },
      effective_call: { done: 1, target: 1 },
      sales_achievement: { done: 1, target: 1 },
      principal_transaction: { done: 1, target: 1 },
      sku_transaction: { done: 1, target: 1 },
      time_gone: { done: 1, target: 1 },
      ar_overdue: { document_count: 1, total_amount: 1 },
      ar_outstanding: { document_count: 1, total_amount: 1 },
      dailyRegularSalesData: {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#7864f4',
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.id === '-' ? `${val.name}` : `(${val.id}) ${val.name}`;
      }
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(0).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getDashboardData() {
      // const queryString = [
      //   'salesman=' + this.salesman,
      //   'salesman_type=' + this.vendorQuery,
      //   'search=' + this.vendorQuery,
      //   'search=' + this.vendorQuery,
      //   'search=' + this.vendorQuery,
      //   'search=' + this.vendorQuery,
      // ];
      this.$http
        .get('/api/report/v1/dashboard/sales-achievement')
        .then((resp) => {
          this.salesman = resp.data.Records.salesman;
          this.registered_outlet = resp.data.Records.registered_outlet;
          this.active_outlet = resp.data.Records.active_outlet;
          this.sales_productivity = resp.data.Records.sales_productivity;

          this.visit_plan = resp.data.Records.visit_plan;
          this.effective_call = resp.data.Records.effective_call;
          this.sales_achievement = resp.data.Records.sales_achievement;

          this.principal_transaction = resp.data.Records.principal_transaction;
          this.sku_transaction = resp.data.Records.sku_transaction;
          this.time_gone = resp.data.Records.time_gone;
          this.ar_overdue = resp.data.Records.ar_overdue;
          this.ar_outstanding = resp.data.Records.ar_outstanding;
          this.dailyRegularSalesData.labels =
            resp.data.Records.daily_regular_sales.map((x) => {
              return x.day;
            });
          this.dailyRegularSalesData.datasets[0].data =
            resp.data.Records.daily_regular_sales.map((x) => {
              return x.value;
            });
        });
    },
    getRegions() {
      this.$http.get('/api/report/v1/dashboard/regions').then((resp) => {
        this.optionRegions = resp.data.Records;
      });
    },
    getOUs() {
      this.$http.get('/api/report/v1/master/ou').then((resp) => {
        this.optionOUs = resp.data.records;
      });
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getSupervisors() {
      this.$http.get('/api/report/v1/dashboard/supervisors').then((resp) => {
        this.optionSupervisors = resp.data.Records;
      });
    },
    getSalesmanTypes() {
      this.$http.get('/api/report/v1/dashboard/salesman-types').then((resp) => {
        this.optionSalesmanTypes = resp.data.Records;
      });
    },
    getSalesman() {
      this.$http.get('/api/report/v1/dashboard/salesmans').then((resp) => {
        this.optionSalesman = resp.data.Records;
      });
    },
  },
  mounted() {
    this.getDashboardData();
    this.getRegions();
    this.getOUs();
    this.getTerritories();
    this.getSupervisors();
    this.getSalesmanTypes();
    this.getSalesman();
  },
  watch: {
    selectedRegions(val) {
      let regionIDs = [];
      let regionCodes = [];
      let regionNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          regionIDs.push(e.id);
          regionCodes.push(e.code);
          regionNames.push(e.name);
        }
      });

      this.regionIDs = regionIDs;
      this.regionCodes = regionCodes;
      this.regionNames = regionNames;
    },
    selectedOUs(val) {
      let ouIDs = [];
      let ouCodes = [];
      let ouNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          ouIDs.push(e.id);
          ouCodes.push(e.code);
          ouNames.push(e.name);
        }
      });

      this.ouIDs = ouIDs;
      this.ouCodes = ouCodes;
      this.ouNames = ouNames;
    },
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedSupervisors(val) {
      let supervisorIDs = [];
      let supervisorCodes = [];
      let supervisorNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          supervisorIDs.push(e.id);
          supervisorCodes.push(e.code);
          supervisorNames.push(e.name);
        }
      });

      this.supervisorIDs = supervisorIDs;
      this.supervisorCodes = supervisorCodes;
      this.supervisorNames = supervisorNames;
    },
    selectedSalesmanTypes(val) {
      let salesmanTypeIDs = [];
      let salesmanTypeCodes = [];
      let salesmanTypeNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          salesmanTypeIDs.push(e.id);
          salesmanTypeCodes.push(e.code);
          salesmanTypeNames.push(e.name);
        }
      });

      this.salesmanTypeIDs = salesmanTypeIDs;
      this.salesmanTypeCodes = salesmanTypeCodes;
      this.salesmanTypeNames = salesmanTypeNames;
    },
    selectedSalesman(val) {
      let salesmanIDs = [];
      let salesmanCodes = [];
      let salesmanNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          salesmanIDs.push(e.id);
          salesmanCodes.push(e.code);
          salesmanNames.push(e.name);
        }
      });

      this.salesmanIDs = salesmanIDs;
      this.salesmanCodes = salesmanCodes;
      this.salesmanNames = salesmanNames;
    },
  },
};
</script>
